import { IonIcon } from "@ionic/react";
import "./btnBack.css";
import { useEffect, useState } from "react";

import { arrowUndoOutline } from "ionicons/icons";

export const BtnBack = () => {
  const [path, setPath] = useState("");

  const back = () => {
    let paths = sessionStorage.getItem("path");

    try {
      let url = "";
      paths = paths.split(",");

      for (let i = 0; i < paths.length; i++) {
        // eslint-disable-next-line no-restricted-globals
        if (paths.at(-1) != location.href) {
          url = paths.at(-1);
        } else {
          url = paths.at(-i);
          break;
        }
      }

      let news_paths = "";

      paths.pop();

      paths.map((value, index) => {
        index === 0 ? (news_paths += `${value}`) : (news_paths += `,${value}`);
      });

      sessionStorage.setItem("path", `${news_paths}`);

      // eslint-disable-next-line no-restricted-globals
      location.href = url;
    } catch (e) {
      // eslint-disable-next-line no-restricted-globals
      location.href = paths;
    }
  };

  useEffect(() => {
    setPath(sessionStorage.getItem("path"));
  }, [path]);

  return (
    <section className="sectionNav">
      {path !== "" ? (
        <button
          className="btnBack"
          onClick={() => {
            back();
          }}
        >
          <IonIcon className="iconSVG" icon={arrowUndoOutline}></IonIcon>
        </button>
      ) : (
        <></>
      )}
    </section>
  );
};
