import { useState } from "react";
import {
  IonContent,
  IonHeader,
  IonMenu,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";

import "./menuOver.css";

function MenuOver() {
  const [login, setLogin] = useState("");
  const [presentAlert] = useIonAlert();

  const logout = async () => {
    try {
      const respone = await fetch("https://mi.yappa.ec/api/salir", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      });
      const data = await respone.json();
      console.log(data);
    } catch (e) {
      console.log(e);
    }
    setLogin("null");

    sessionStorage.setItem("id", "null");
    sessionStorage.setItem("token", "null");
    sessionStorage.setItem("remember", "1");
    sessionStorage.setItem("nombre", "");

    presentAlert({
      header: "Ha cerrado sesión correctamente",
      cssClass: "MsgOk",
      buttons: ["Ok"],
    });
  };

  const redirect = (href: any) => {
    let path = sessionStorage.getItem("path");

    // eslint-disable-next-line no-restricted-globals
    path !== "" ? (path += `,${location.href}`) : (path += `${location.href}`);

    sessionStorage.setItem("path", `${path}`);

    // eslint-disable-next-line no-restricted-globals
    location.href = href;
  };

  return (
    <>
      <IonMenu contentId="main-content" type="push" side="end">
        <IonHeader>
          <IonToolbar>
            <section className="ToolBarNavMenu">
              <img
                className="logoMenuYappa"
                alt="logo yappa"
                src={
                  "https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/9fc0ed25-63ad-4365-d2e4-6739bc7f2c00/public"
                }
              />
            </section>
          </IonToolbar>
        </IonHeader>
        <IonContent class="contenidoMenu">
          <section className="seccionOpciones">
            <section className="seccionInfoUser">
              <div className="InfoUsuario">
                <h4>Hola</h4>
                <h2>
                  {
                    // eslint-disable-next-line no-restricted-globals
                    sessionStorage.getItem("nombre") ||
                      "Es tu momento de Yappear"
                  }
                </h2>
              </div>
            </section>

            <section className="StiloOpciones">
              <div className="opcionesMenu">
                <button
                  className="textoBtnMenu"
                  onClick={() => {
                    redirect("/inicio");
                  }}
                >
                  <h5>Inicio</h5>
                </button>
              </div>
              <div className="opcionesMenu">
                <button
                  className="textoBtnMenu"
                  onClick={() => {
                    redirect("/misyappas");
                  }}
                >
                  <h5>Mis yappas</h5>
                </button>
              </div>
              <div className="opcionesMenu">
                <button
                  className="textoBtnMenu"
                  onClick={() => {
                    redirect("/historial");
                  }}
                >
                  <h5>Historial</h5>
                </button>
              </div>
              {/* {sessionStorage.getItem("id") !== "null" &&
              sessionStorage.getItem("id") != null ? (
                <div className="opcionesMenu">
                  <button
                    className="textoBtnMenu"
                    onClick={() => {
                      redirect("/perfil");
                    }}
                  >
                    <h5>Perfil</h5>
                  </button>
                </div>
              ) : (
                false
              )} */}
              <div className="opcionesMenu">
                <button
                  className="textoBtnMenu"
                  onClick={() => {
                    redirect("/TerminosCondiciones");
                  }}
                >
                  <h5>Términos y condiciones</h5>
                </button>
              </div>
            </section>

            <section className="StiloOpcionesInferior">
              {sessionStorage.getItem("id") === "null" ||
              sessionStorage.getItem("id") == null ||
              sessionStorage.getItem("id") === undefined ? (
                <button
                  className="ICSesion"
                  onClick={() => {
                    redirect("/login");
                  }}
                >
                  {" "}
                  Iniciar Sesión
                </button>
              ) : (
                <button
                  className="ICSesion"
                  onClick={() => {
                    logout();
                  }}
                >
                  {" "}
                  <h5>Cerrar Sesión</h5>
                </button>
              )}
            </section>
          </section>
        </IonContent>
      </IonMenu>
    </>
  );
}
export default MenuOver;
