import {
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import MenuOver from "../../componentes/header/MenuOver";
import { BtnBack } from "../../componentes/btnBack/BtnBack";

export const MiPerfil = () => {
  return (
    <>
      <MenuOver />
      <IonPage id="main-content">
        <IonHeader>
          <section className="headerNavBarA">
            <div className="cssBack">
              <BtnBack />
            </div>
            <div>
              <a href={"/inicio"}>
                <img
                  className="logoMenuYappa"
                  alt="logo yappa"
                  src={
                    "https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/9fc0ed25-63ad-4365-d2e4-6739bc7f2c00/public"
                  }
                />
              </a>
            </div>
            <div className="cssBottonMenu">
              <IonMenuButton></IonMenuButton>
            </div>
          </section>
        </IonHeader>
        <IonTitle>Mi Perfil</IonTitle>
      </IonPage>
    </>
  );
};
