import { useState, useEffect } from "react";
import { IonCard } from "@ionic/react";

import Sliders from "../Sliders/Sliders";
import AddToPath from "../../funcs/AddToPath";

import classes from "./Category.module.css";



type Categories = {
  subcategoria: string;
  id: number;
  url_img: string;
}

export const Category = () => {
  const [categories, setCategories] = useState<Categories[]>([]);

  useEffect(() => {
    const getCategories = async () => {
      const response = await fetch("https://mi.yappa.ec/api/subcategorias");
      const data = await response.json();

      setCategories(data);
    }

    getCategories();
  }, []);
  return (
    <section className={classes.formatSeccion}>
      <h2 className={classes.tituloSeccion}>Categorías</h2>
      {/* <div className={classes.container}> */}
      <Sliders variableWidth={true}  slidesToScroll={5} infinite={false}>
        {categories.map((value, index) => {
          return (
            <IonCard key={index}
              className={classes.cardCategory}
              onClick={() => { AddToPath(`yappa_categoria/${value.id}`) }}
            >
              <section className={classes.img_category_container}>
                <img
                  id="imgCat"
                  src={value.url_img}
                  alt={value.subcategoria}
                />
                <br />
                <b className={classes.categoriaTexto}>{value.subcategoria}</b>
              </section>
            </IonCard>
          );
        })}
      </Sliders>
      {/* </div> */}
    </section>
  );
};
