/* eslint-disable no-restricted-globals */
import { type ReactElement, type FormEvent, useState, useEffect } from "react";
import classes from "./BuscadorGlobal.module.css";
import { IonSearchbar } from "@ionic/react";
import { ResultadoBuscador } from "../resultadoBuscador/ResultadoBuscador";
import { Swiper, SwiperSlide } from "swiper/react";
import "@ionic/react/css/ionic-swiper.css";
import "swiper/css/pagination";

import ScreenSize from "../../funcs/ScreenSize";

type Yappas = {
  id: number;
  url: string;
  nombre: string;
  ciudad: string;
  negocio: string;
  fecha_expiracion: string;
  subcategoria: string;
  descripción: string;
  cantidad: string;
}

export const BuscadorGlobal = () => {
  const [mensaje, setMensaje] = useState<string | ReactElement>("");
  const [numCateg, setnumCateg] = useState<number>(0);
  const [yappasBuscadas, setYappasBuscadas] = useState<Yappas[]>([]);

  useEffect(() => { ScreenSize(setnumCateg); }, []);

  const mensaje_yappas = (mensaje: string | ReactElement) => {
    setMensaje(<div className={classes.resulNo}>{mensaje}</div>);

    setTimeout(() => {
      setMensaje("");
    }, 5000);
  };

  const buscar = async (palabra: string) => {
    setYappasBuscadas([]);

    const body = {
      texto: palabra,
    };

    if (palabra.trim() !== "") {
      const response = await fetch("https://mi.yappa.ec/api/yappa_busqueda", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      });

      const data = await response.json();
      let array = [];

      (await data.original.length) <= 0 ?
        mensaje_yappas("No hay Yappas disponibles para tu búsqueda")
        : array = await data.original;

        console.log(array);

      return array;
    } else {
      setMensaje("");
      return [];
    }
  };

  const buscarYappasControlador = async (event: FormEvent<HTMLIonSearchbarElement>) => {
    setYappasBuscadas(await buscar(event.currentTarget.value!));
  }

  return (
    <>
      <IonSearchbar
        className={classes.search}
        onKeyUp={(event) => { buscarYappasControlador(event) }}
        type="search"
        placeholder="¡Encuentra tu YAPPA!"
        showClearButton="never"
      ></IonSearchbar>
      <div className={classes.seccionSwiper}>
        {yappasBuscadas.length > 0 ? (
          <Swiper
            slidesPerView={numCateg}
            spaceBetween={0}
          >
            {yappasBuscadas.map((value: Yappas, index: number) => {
              return (
                <SwiperSlide key={index}>
                  <ResultadoBuscador
                    id={value.id}
                    url={value.url}
                    nombre={value.nombre}
                    ciudad={value.ciudad}
                    negocio={value.negocio}
                    fecha={value.fecha_expiracion}
                    categoria={value.subcategoria}
                    descripcion={value.descripción}
                    cantidad={value.cantidad}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          <>{mensaje}</>
        )}
      </div>
    </>
  );
};
