import { useState, useEffect } from "react";
import {
  IonHeader,
  IonContent,
  IonPage,
  IonMenuButton,
  IonGrid,
  IonCardContent,
  IonCard,
} from "@ionic/react";
import { BtnBack } from "../../componentes/btnBack/BtnBack";
import MenuOver from "../../componentes/header/MenuOver";
import { BtnsLikeShare } from "../../componentes/botonesLikeShare/BtnsLikeShare";
import QRCode from "react-qr-code";

const Qr = () => {
  const [yappa, setYappa] = useState<any[]>([]);

  const getYappaInfo = async () => {
    // eslint-disable-next-line no-restricted-globals
    const id_yappa = location.href.split("/").at(-1);

    const response = await fetch(
      "http://yappa.grow.ec/api/yappa_informacion",
      {
        method: "POST",
        body: JSON.stringify({ id: id_yappa }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    const data = await response.json();

    setYappa(data);
    console.log(data);
  };

  useEffect(() => {
    getYappaInfo();
  }, []);

  return (
    <>
      <MenuOver />
      <IonPage id="main-content">
        <IonHeader>
          <section className="headerNavBarA">
            <div className="cssBack">
              <BtnBack />
            </div>
            <div>
              <a href={"/inicio"}>
                <img
                  className="logoMenuYappa"
                  alt="logo yappa"
                  src={
                    "https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/9fc0ed25-63ad-4365-d2e4-6739bc7f2c00/public"
                  }
                />
              </a>
            </div>
            <div className="cssBottonMenu">
              <IonMenuButton></IonMenuButton>
            </div>
          </section>
        </IonHeader>
        <IonContent>
          <IonCard className="fondoPage">
            <section className="GridCard">
              <div className="ColCardIMG">
                <img className="imagenYappa" src={yappa[0]?.url} alt="QR"></img>
              </div>
              <div className="ColCardInformation">
                <IonCardContent>
                  <IonGrid>
                    <section className="tituloBtnLikeShare">
                      <div className="headerYappaInformacion">
                        <div>
                          <h6 className="nombreCategoria">
                            {yappa[0]?.subcategoria}
                          </h6>
                          <h4 className="nombreNegocio">{yappa[0]?.negocio}</h4>
                          <h2 className="nombreYappa">{yappa[0]?.nombre}</h2>
                        </div>
                        <div className="botoneslikeshare">
                          <BtnsLikeShare id_yappa={yappa[0]?.id} />
                        </div>
                      </div>
                      <div style={{ height: "auto", marginTop: 24, marginLeft: "auto", marginRight: "auto" , maxWidth: 400, width: "100%" }}>
                        <QRCode
                          size={500}
                          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                          value={`https://mi.yappa.ec/api/yappas/${yappa[0]?.id}/${sessionStorage.getItem("id")}`}
                          level={"L"}
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                    </section>
                  </IonGrid>
                </IonCardContent>
              </div>
            </section>
          </IonCard>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Qr;
