/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonRow,
  IonTitle,
} from "@ionic/react";
import React from "react";
import { BtnsLikeShare } from "../botonesLikeShare/BtnsLikeShare";
import "./resultadoBuscador.css";

import { BtnVerYappa } from "../btnVerYappa/BtnVerYappa";

export const ResultadoBuscador = (props: any) => {
  const { id, url, nombre, negocio, fecha_expiracion, categoria, cantidad } =
    props;

  const get_day = (date: any) => {
    let allDate = new Date(date);

    let result = "";
    try {
      result = allDate.toString().split(" ")[2];
    } catch (error) {}

    return result;
  };

  const get_month = (date: any) => {
    const month: any = {
      Jan: "enero",
      Feb: "febrero",
      Mar: "marzo",
      Apr: "abril",
      May: "mayo",
      Jun: "junio",
      Jul: "julio",
      Aug: "agosto",
      Sep: "septiembre",
      Oct: "octubre",
      Nov: "noviembre",
      Dec: "diciembre",
    };

    let allDate = new Date(date);

    let result = "";
    try {
      result = month[allDate.toString().split(" ")[1]];
    } catch (error) {}

    return result;
  };

  const trySplit = (value: string) => {
    let result = "";
    try {
      result = value.split(" ")[0];
    } catch {}

    return result;
  };

  return (
    <section>
      <IonCard
        className="colorCard"
        onClick={() => {
          let path = sessionStorage.getItem("path");
          path !== ""
            ? (path += `,${location.href}`)
            : (path += `${location.href}`);
          sessionStorage.setItem("path", `${path}`);
          location.href = `yappa/${id}`;
        }}
      >
        <img className="imgCard" src={url}></img>
        <IonCardContent>
          <div className="tituloandbtnBusqueda">
            <div className="infoYappanego">
              <h6 className="nombreCategoria">{categoria}</h6>
              <h4 className="nombreEmpresa">{negocio}</h4>
              <h2 className="tituloBusqueda">{nombre}</h2>
            </div>
            <div className="botonesLike">
              <BtnsLikeShare id_yappa={id} />
            </div>
          </div>
          <div className="textosDetalles">
            {/* <p>Vigencia:</p> */}
            <p>Yappas disponibles</p>
          </div>
          <div className="textosDetallesBusqueda2">
            <div className="cantidadYappas">
              <h4>{cantidad}</h4>
            </div>
            <div className="detallefecha">
              <h4 className="infoFecha">
                {/* Hasta el {get_day(trySplit(fecha_expiracion))} de{" "}
                {get_month(trySplit(fecha_expiracion))}{" "} */}
              </h4>
            </div>
          </div>

          <div className="btnVerYappa">
            <BtnVerYappa obj={id} />
          </div>
        </IonCardContent>
      </IonCard>
    </section>
  );
};
