import { IonButton, IonButtons, IonIcon, useIonAlert } from "@ionic/react";
import { useState } from "react";
import { RWebShare } from "react-web-share";
import "./btnsLikeShare.css";
import { heartOutline } from "ionicons/icons";
import { shareSocialOutline } from "ionicons/icons";

import AddToPath from "../../funcs/AddToPath";

export const BtnsLikeShare = (props) => {
  const { id_yappa } = props;
  const [yappaGuardada, setYappaGuardada] = useState([]);
  const [presentAlert] = useIonAlert();

  const validarSesion = () => {
    return sessionStorage.getItem("id") != null &&
      sessionStorage.getItem("id") !== "null"
      ? true
      : false;
  };

  const getYappasGuardadas = async () => {
    const body = {
      id: +sessionStorage.getItem("id"),
    };

    const response = await fetch(
      "https://mi.yappa.ec/api/mostrar_yappa_guardada",
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    const data = await response.json();
    setYappaGuardada(data.original);
  };

  const save = async (id_yappa) => {
    if (validarSesion()) {
      const body = {
        id_usuario: +sessionStorage.getItem("id"),
        id_yappa: id_yappa,
      };

      const response = await fetch("https://mi.yappa.ec/api/guardar_yappa", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      });

      const data = await response.json();
      const original = await data?.original;
      const messge = await original?.original;

      await presentAlert({
        header: messge.mensaje,
        cssClass: "MsgOk",
        buttons: ["Ok"],
      });

      getYappasGuardadas();
    } else {
      sessionStorage.save = id_yappa;

      await presentAlert({
        header: "Recuerda que para guardar tu yappa debes tener una cuenta.",
        cssClass: "MsgError",
        buttons: [
          {
            text: "Ingresar",
            handler: () => {
              AddToPath("/login");
            },
          },
          {
            text: "Crear cuenta",
            handler: () => {
              AddToPath("/registroUser");
            },
          },
        ],
      });
    }
  };

  return (
    <IonButtons class="btns">
      <IonButton
        class="btnLike"
        onClick={() => {
          save(id_yappa);
        }}
      >
        <IonIcon icon={heartOutline} />
      </IonButton>
      <RWebShare
        data={{
          text: "¡Hey! te tengo una Yappa buenísima, Yappeala aquí: ",
          url: `https://mi.yappa.ec/yappa/${id_yappa}`,
          title: "Comparte tu Yappa",
        }}
      >
        <IonButton class="btnShare">
          <IonIcon icon={shareSocialOutline}></IonIcon>
        </IonButton>
      </RWebShare>
    </IonButtons>
  );
};
