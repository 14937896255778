import { useRef, useState, useEffect } from "react";
import {
  IonButtons,
  IonContent,
  IonInput,
  IonItem,
  IonList,
  IonPage,
  IonRadio,
  IonRadioGroup,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import "./RegistroUser.css";

export const Registro = () => {
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [email, setEmail] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [password, setPassword] = useState("");
  const [passwordTest, setPasswordTest] = useState("");
  const [celular, setCelular] = useState("");
  const [presentAlert] = useIonAlert("");
  const [present, dismiss] = useIonLoading();
  const swiperRef = useRef(null);

  const [count, setCount] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    setNombre("");
    setApellido("");
    setCiudad("");
    setPassword("");
    setPasswordTest("");
    setCelular("");
  };

  const redirect = (href) => {
    let path = sessionStorage.getItem("path");
    // eslint-disable-next-line no-restricted-globals
    path !== "" ? (path += `,${location.href}`) : (path += `${location.href}`);
    // eslint-disable-next-line no-restricted-globals
    sessionStorage.setItem("path", `${path}`);
    // eslint-disable-next-line no-restricted-globals
    location.href = href;
  };

  const handleClick = (event) => {
    event.currentTarget.disabled = true;
    console.log(event);
    console.log(event.currentTarget.disabled);
    console.log("button clicked");
  };

  const signup = async () => {
    try {
      const body = {
        email: email,
        clave: password,
        clave_confirmation: passwordTest,
        nombre_completo: nombre,
        celular: celular,
        direccion: ciudad,
      };

      const response = await fetch("https://mi.yappa.ec/api/registro", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      });

      const data = await response.json();
      console.log(data);
      if (data.mensaje === "usuario registrado correctamente") {
        await presentAlert({
          header: data.mensaje,
          cssClass: "MsgOk",
          buttons: ["Ok"],
        });
        await login();
      }
    } catch (error) {
      presentAlert({
        header: "No se pudo realizar el registro",
        cssClass: "MsgError",
        buttons: ["Ok"],
      });
      console.log(error);
    }
  };
  const login = async () => {
    const body = {
      email: email,
      clave: password,
    };

    const response = await fetch("https://mi.yappa.ec/api/ingreso", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    });
    const data = await response.json();

    try {
      const key = await data.access_token.split("|");

      sessionStorage.setItem("token", key.at(-1));
      sessionStorage.setItem("id", data.id);
      sessionStorage.setItem("remember", "0");

      presentAlert({
        header: "Ha iniciado sesión correctamente",
        cssClass: "MsgOk",
        buttons: ["Ok"],
      });

      setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.href = "";
        console.log("Ingreso");
      }, 2000);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.on("slideChange", handleSlideChange);
    }
  }, []);

  const handleSlideChange = () => {};

  const nextPage = () => {
    setCount(count + 1);
  };

  const nextPrev = () => {
    if (count - 1 == 0) {
      // eslint-disable-next-line no-restricted-globals
      location.href = "https://yappa.ec/login";
    } else {
      setCount(count - 1);
    }
  };

  return (
    <IonPage style={{ backgroundColor: "#fa213b", heigth: "100vh" }}>
      <IonContent class="contentPage">
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          style={{
            backgroundColor: "#fa213b",
            display: "grid",
            gridTemplateColumns: "100%",
            gridTemplateRows: "100%",
            justifyItems: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {count === 0 ? (
            <section className="slideInfo slidereg1">
              <section className="sectionLogoReg">
                <img
                  className="logoYappaSlide"
                  src="https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/e6e10e5e-fc6b-4af9-3fd6-a3aa52257c00/public"
                  alt="Descripción de la imagen"
                />
              </section>
              <section className="sectionBienvenida">
                <div>
                  <span>
                    ¡Te damos la bienvenida a la mejor comunidad de yaperos del
                    Ecuador!
                  </span>
                </div>
              </section>
              <section>
                <div>
                  <p>Por favor, sigue estas reglas</p>
                </div>
                <div>
                  <ul>
                    <li>
                      <b>
                        Al registrate en nuestra app, aceptas todos nuestros
                        te&#769;rminos y condiciones
                      </b>
                    </li>
                    <li>
                      <b>
                        El titular de la cuenta es responsable del uso que se le
                        da a la misma, y de las transacciones realizadas
                      </b>
                    </li>
                  </ul>
                </div>
              </section>
              <div
                className="divMailBTN"
                style={{
                  display: "grid",
                  gridTemplateColumns: "100%",
                  gridTemplateRows: "auto",
                  justifyItems: "center",
                  gap: "20px",
                }}
              >
                <button
                  className="btnAceptoReg"
                  type="button"
                  onClick={() => {
                    nextPage();
                  }}
                >
                  <p>Acepto</p>
                </button>
              </div>
            </section>
          ) : (
            false
          )}

          {count === 1 ? (
            <section className="slideInfo">
              <section className="sectionLogoReg">
                <img
                  className="logoYappaSlide"
                  src="https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/e6e10e5e-fc6b-4af9-3fd6-a3aa52257c00/public"
                  alt="Descripción de la imagen"
                />
              </section>
              <section>
                <div>
                  <span>¿Cuál es tu dirección de correo electrónico?</span>
                </div>
              </section>
              <section>
                <div>
                  <p>
                    <b>
                      No pierdas acceso a tu cuenta; verifica tu correo
                      electrónico.
                    </b>
                  </p>
                </div>
                <div className="divMailBTN">
                  <IonInput
                    class="inputMailReg"
                    placeholder=" Ingresa tu email"
                    type="email"
                    value={email}
                    onIonChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div
                  className="divContBTN"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "100%",
                    gridTemplateRows: "auto",
                    justifyItems: "center",
                    gap: "20px",
                  }}
                >
                  <button
                    className="btnContinuarReg"
                    onClick={async () => {
                      if (
                        email !== "" &&
                        email.search(
                          /(?:[a-z0-9!#$%&'*+\=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
                        ) >= 0
                      ) {
                        const response = await fetch(
                          "https://mi.yappa.ec/api/validar_password",
                          {
                            method: "POST",
                            body: JSON.stringify({ email: email }),
                            headers: {
                              "Content-type": "application/json; charset=UTF-8",
                              "Access-Control-Allow-Origin": "*",
                            },
                          }
                        );

                        const data = await response.json();

                        if (
                          data.mensaje == "Ya existe una cuenta con este correo"
                        ) {
                          presentAlert({
                            header:
                              "Correo ya registrado. Inténte con un correo diferente.",
                            cssClass: "MsgError",
                            buttons: ["Ok"],
                          });

                          setTimeout(() => {
                            // eslint-disable-next-line no-restricted-globals
                            location.href = "https://yappa.ec/login";
                          }, 2000);
                        } else {
                          nextPage();
                        }
                      } else {
                        present({
                          message: "¡Ups! Debes colocar un correo",
                          duration: 2000,
                          cssClass: "MsgError",
                          translucent: true,
                          showBackdrop: false,
                        });
                      }
                    }}
                  >
                    <p>Continuar </p>
                  </button>
                  <button
                    className="btnContinuarReg"
                    onClick={() => {
                      nextPrev();
                    }}
                  >
                    <p>Regresar </p>
                  </button>
                </div>
              </section>

              <section></section>
            </section>
          ) : (
            false
          )}

          {count === 2 ? (
            <section className="slideInfo">
              <section className="sectionLogoReg">
                <img
                  className="logoYappaSlide"
                  src="https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/e6e10e5e-fc6b-4af9-3fd6-a3aa52257c00/public"
                  alt="Descripción de la imagen"
                />
              </section>
              <section>
                <div>
                  <span>¿Cuál es tu nombre completo?</span>
                </div>
              </section>
              <section>
                <div className="divNombreReg">
                  <IonInput
                    class="inputMailReg"
                    placeholder=" Ingresa tu nombre completo"
                    type="text"
                    value={nombre}
                    onKeyUp={(e) => {
                      setNombre(e.target.value);
                      console.log(e.target.value);
                    }}
                  />
                </div>
              </section>
              <section>
                <div>
                  <p>
                    <b>
                      Tú nombre sera el identificador para canjear las yappas.
                    </b>
                  </p>
                  <p>Es importante que seas el titular de la cuenta</p>
                </div>
              </section>
              <div
                className="divMailBTN"
                style={{
                  display: "grid",
                  gridTemplateColumns: "100%",
                  gridTemplateRows: "auto",
                  justifyItems: "center",
                  gap: "20px",
                }}
              >
                <button
                  className="btnContinuarReg"
                  onClick={() => {
                    if (nombre !== "") {
                      nextPage();
                    } else {
                      present({
                        message: "¡Ups! Debes ingresar su nombre",
                        duration: 2000,
                        cssClass: "MsgError",
                        translucent: true,
                        showBackdrop: false,
                      });
                    }
                  }}
                >
                  <p>Continuar </p>
                </button>
                <button
                  className="btnContinuarReg"
                  onClick={() => {
                    nextPrev();
                  }}
                >
                  <p>Regresar </p>
                </button>
              </div>
            </section>
          ) : (
            false
          )}

          {count === 3 ? (
            <section className="slideInfo">
              <section className="sectionLogoReg">
                <img
                  className="logoYappaSlide"
                  src="https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/e6e10e5e-fc6b-4af9-3fd6-a3aa52257c00/public"
                  alt="Descripción de la imagen"
                />
              </section>
              <section>
                <div>
                  <span>¿Cuál es tu número de celular?</span>
                </div>
              </section>
              <section>
                <div className="divMailBTN">
                  <IonInput
                    class="inputMailReg"
                    placeholder=" (+593) 09 xx-xxx-xxx"
                    type="num"
                    value={celular}
                    onKeyUp={(e) => {
                      setCelular(e.target.value);
                      console.log(e.target.value);
                    }}
                  />
                  <p>
                    <b>
                      Al tocar en continuar, Yappa guardara tu número celular,
                      para verificar y añadirlo en nuevas funciones de seguridad
                      progresivamente.
                    </b>
                  </p>
                </div>
              </section>
              <div
                className="divMailBTN"
                style={{
                  display: "grid",
                  gridTemplateColumns: "100%",
                  gridTemplateRows: "auto",
                  justifyItems: "center",
                  gap: "20px",
                }}
              >
                <button
                  className="btnContinuarReg"
                  onClick={() => {
                    if (celular !== "") {
                      nextPage();
                    } else {
                      present({
                        message: "¡Ups! Debes ingresar su celular",
                        duration: 2000,
                        cssClass: "MsgError",
                        translucent: true,
                        showBackdrop: false,
                      });
                    }
                  }}
                >
                  <p>Continuar </p>
                </button>
                <button
                  className="btnContinuarReg"
                  onClick={() => {
                    nextPrev();
                  }}
                >
                  <p>Regresar </p>
                </button>
              </div>
            </section>
          ) : (
            false
          )}

          {count === 4 ? (
            <section className="slideInfo">
              <section className="sectionLogoReg">
                <img
                  className="logoYappaSlide"
                  src="https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/e6e10e5e-fc6b-4af9-3fd6-a3aa52257c00/public"
                  alt="Descripción de la imagen"
                />
              </section>
              <section className="sectionBienvenida">
                <div>
                  <span>Define una contraseña</span>
                </div>
              </section>
              <section>
                <div className="divNombreReg">
                  <IonInput
                    class="inputMailReg"
                    placeholder=" Ingresa tu contraseña"
                    type="password"
                    value={password}
                    onKeyUp={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>
                <div className="divNombreReg">
                  <IonInput
                    type="password"
                    class="inputMailReg"
                    placeholder=" Repite tu contraseña"
                    value={passwordTest}
                    onKeyUp={(e) => {
                      setPasswordTest(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <p>
                    {" "}
                    <b>
                      {" "}
                      Recuerda que tu contraseña debe cumplir ciertos
                      para&#769;metros:
                    </b>{" "}
                  </p>
                  <ul>
                    <li>
                      <b>Mi&#769;nimo 8 caracteres</b>
                    </li>
                    <li>
                      <b>
                        Combinación de caracteres (Mayu&#769;sculas,
                        minu&#769;sculas, números y o caracteres especiales)
                      </b>
                    </li>
                  </ul>
                </div>
              </section>
              <div
                className="divMailBTN"
                style={{
                  display: "grid",
                  gridTemplateColumns: "100%",
                  gridTemplateRows: "auto",
                  justifyItems: "center",
                  gap: "20px",
                }}
              >
                <button
                  className="btnContinuarReg"
                  onClick={() => {
                    let passwords =
                      password.search(
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/
                      ) >= 0;

                    let passwordConfirm =
                      passwordTest.search(
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/
                      ) >= 0;

                    if (!passwords || !passwordConfirm) {
                      presentAlert({
                        header:
                          "Las claves no constan de mayúsculas, minúsculas, números, caracteres especiales y mínimo 8 caracteres",
                        cssClass: "MsgError",
                        buttons: ["Ok"],
                      });
                    } else if (password != passwordTest) {
                      presentAlert({
                        header: "Las claves no coinciden entre ellas",
                        cssClass: "MsgError",
                        buttons: ["Ok"],
                      });
                    } else {
                      nextPage();
                    }
                  }}
                >
                  <p>Continuar </p>
                </button>
                <button
                  className="btnContinuarReg"
                  onClick={() => {
                    nextPrev();
                  }}
                >
                  <p>Regresar </p>
                </button>
              </div>
            </section>
          ) : (
            false
          )}

          {count === 5 ? (
            <section className="slideInfo">
              <section className="sectionLogoReg">
                <img
                  className="logoYappaSlide"
                  src="https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/e6e10e5e-fc6b-4af9-3fd6-a3aa52257c00/public"
                  alt="Descripción de la imagen"
                />
              </section>
              <section className="sectionBienvenida">
                <div>
                  <span>¿En que&#769; ciudad estas?</span>
                </div>
              </section>
              <section>
                <div className="divNombreReg">
                  <IonInput
                    class="inputMailReg"
                    placeholder=" Ingresa tu ciudad"
                    type="text"
                    value={ciudad}
                    onKeyUp={(e) => setCiudad(e.target.value)}
                  />
                </div>
                <div>
                  <p>
                    <b>
                      Se&#769; el primer Yapeador de tu ciudad, y
                      convie&#769;rtete en la envidia de tus amigos.
                    </b>
                  </p>
                </div>
              </section>
              <div
                className="divMailBTN"
                style={{
                  display: "grid",
                  gridTemplateColumns: "100%",
                  gridTemplateRows: "auto",
                  justifyItems: "center",
                  gap: "20px",
                }}
              >
                <button
                  className="btnContinuarReg"
                  onClick={() => {
                    if (ciudad !== "") {
                      nextPage();
                    } else {
                      present({
                        message: "¡Ups! Debes ingresar su ciudad",
                        duration: 2000,
                        cssClass: "MsgError",
                        translucent: true,
                        showBackdrop: false,
                      });
                    }
                  }}
                >
                  <p>Continuar </p>
                </button>
                <button
                  className="btnContinuarReg"
                  onClick={() => {
                    nextPrev();
                  }}
                >
                  <p>Regresar </p>
                </button>
              </div>
            </section>
          ) : (
            false
          )}

          {count === 6 ? (
            <section className="slideInfo">
              <section className="sectionLogoReg">
                <img
                  className="logoYappaSlide"
                  src="https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/e6e10e5e-fc6b-4af9-3fd6-a3aa52257c00/public"
                  alt="Descripción de la imagen"
                />
              </section>
              <section className="sectionBienvenida">
                <div>
                  <span>¿Con qué género te identificas?</span>
                </div>
              </section>
              <section>
                <div>
                  <IonList>
                    <IonRadioGroup allowEmptySelection={true} value="turtles">
                      <IonItem>
                        <p>Mujer</p>
                        <IonRadio slot="end" value="dogs"></IonRadio>
                      </IonItem>

                      <IonItem>
                        <p>Hombre</p>
                        <IonRadio slot="end" value="cats"></IonRadio>
                      </IonItem>

                      <IonItem>
                        <p>No Binario</p>
                        <IonRadio slot="end" value="turtles"></IonRadio>
                      </IonItem>
                    </IonRadioGroup>
                  </IonList>
                </div>
              </section>
              <section>
                <div>
                  <p>
                    <b>
                      Esta información nos sera&#769; u&#769;til para poder
                      ofrecerte yappas que puedan ser tu intere&#769;s.
                    </b>
                  </p>
                </div>
              </section>

              <section>
                <IonButtons
                  className="seccionBotonesReg"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "100%",
                    gridTemplateRows: "auto",
                    justifyItems: "center",
                    gap: "20px",
                  }}
                >
                  <button
                    className="btnRegistrar"
                    type="submit"
                    onClick={(e) => {
                      signup();
                      handleClick(e);
                    }}
                  >
                    Vamos a Yappear
                  </button>
                  <button
                    className="btnCancelar"
                    type="button"
                    onClick={() => {
                      redirect("/inicio");
                    }}
                  >
                    Mejor no
                  </button>
                </IonButtons>
              </section>
            </section>
          ) : (
            false
          )}
        </form>
      </IonContent>
    </IonPage>
  );
};
