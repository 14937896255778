/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-globals */
import React from "react";

import "./btnVerYappa.css";
import { Link } from "react-router-dom";

export const BtnVerYappa = (props) => {
  const { obj } = props;
  return (
    <Link
      to={() => {
        let path = sessionStorage.getItem("path");
        path !== ""
          ? (path += `,${location.href}`)
          : (path += `${location.href}`);
        sessionStorage.setItem("path", `${path}`);
        return `/yappa/${obj}`;
      }}
      className="linkBtnVer"
      onClick={() => {
        let path = sessionStorage.getItem("path");
        path !== ""
          ? (path += `,${location.href}`)
          : (path += `${location.href}`);
        sessionStorage.setItem("path", `${path}`);
        location.href = `/yappa/${obj}`;
      }}
    >
      <button className="btnVerYappainfo">
        <h3 className="textoVerYappa"> Ver Yappa </h3>
        <img
          className="imgBtnveryappa"
          src="https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/189794e5-5209-40a6-9fbb-3b4d97feee00/public"
          alt="icon"
        />
      </button>
    </Link>
  );
};
